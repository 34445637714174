<div
  class="fixed top-0 left-1/2 translate-x-[-50%] w-full sm:w-responsivelarge bg-white flex items-center justify-between z-50"
>
  <app-page-header
    (actionClick)="emitKey($event)"
    class="w-full"
    [title]="'Chỉnh sửa Landing Page'"
    [actionList]="pageHeaderIcons"
  ></app-page-header>
</div>
<div id="anchor"></div>
<div class="h-fit">
  <div class="mt-10 mb-10">
    <!--Khi chưa có thành phần nào-->
    <div *ngIf="isNewComplete" class="text-center">
      <div
        class="bg-empty-landing-page bg-no-repeat h-[260px] m-auto object-cover"
      ></div>
      <div class="font-semibold">Thỏa sức sáng tạo với landing page</div>
    </div>
    <!--Khi đã có ít nhất 1 thành phần-->
    <ng-container
      *ngIf="!isNewComplete"
      [ngTemplateOutlet]="Detail"
    ></ng-container>
    <!--Nút add ở cuối trang edit-->
    <ng-container
      [ngTemplateOutlet]="buttonAdd"
      [ngTemplateOutletContext]="{
        index: isNewComplete ? undefined : listAllItems.length
      }"
    >
    </ng-container>
  </div>
</div>
<!-- add dialog -->
<nz-drawer
  [(nzVisible)]="isVisible"
  nzHeight="450px"
  nzPlacement="bottom"
  nzTitle="Thêm thành phần danh mục"
  (nzOnClose)="isVisible = false"
  [nzClosable]="true"
  nzWrapClassName="responsive-modal"
>
  <div *nzDrawerContent class="flex flex-col gap-y-4">
    <button
      (click)="addContent()"
      class="button-success py-3 w-full hover:opacity-75"
    >
      Thêm nội dung
    </button>
    <app-upload-image
      class="w-full"
      (imageAfterCropped)="addImage($event)"
      [type]="TypeUpload.Custom"
    >
      <div
        class="button-success py-3 w-full hover:opacity-75 block text-center"
      >
        Thêm hình ảnh
      </div>
    </app-upload-image>
    <button
      (click)="addLink()"
      class="button-success py-3 w-full hover:opacity-75"
    >
      Thêm liên kết
    </button>
    <button
      *ngIf="!isHasCTA"
      (click)="addAction()"
      class="button-success py-3 w-full hover:opacity-75"
    >
      Thêm kêu gọi hành động
    </button>
    <button
      (click)="addButton()"
      class="button-success py-3 w-full hover:opacity-75"
    >
      Thêm nút bấm
    </button>
  </div>
</nz-drawer>
<!-- detail template -->
<ng-template #Detail>
  <div>
    <div *ngFor="let item of listAllItems; index as i" [id]="item.id">
      <div [ngSwitch]="item.type" class="relative">
        <div
          (click)="changeTypeFooter(TypeFooter.Image, item, $event, i)"
          *ngSwitchCase="ItemType.image"
          class="container normal"
          [id]="item.id"
        >
          <app-type-image
            class="item"
            [index]="i"
            [id]="item.id"
            [isIntersection]="item.isIntersection"
          ></app-type-image>
        </div>
        <div
          *ngSwitchCase="ItemType.content"
          class="container normal"
          [id]="item.id"
          (click)="changeTypeFooter(TypeFooter.Content, item, $event, i)"
        >
          <app-type-content
            class="item"
            [id]="item.id"
            [isIntersection]="item.isIntersection"
          ></app-type-content>
        </div>
        <div
          *ngSwitchCase="ItemType.connective"
          class="container normal"
          [id]="item.id"
          (click)="changeTypeFooter(TypeFooter.Link, item, $event, i)"
        >
          <app-type-link
            class="item"
            [id]="item.id"
            [isIntersection]="item.isIntersection"
          ></app-type-link>
        </div>
        <div
          #CTAElement
          *ngSwitchCase="ItemType.action"
          class="container normal"
          [id]="item.id"
          (click)="changeTypeFooter(TypeFooter.Action, item, $event, i)"
        >
          <app-type-action
            class="item"
            [id]="item.id"
            [isIntersection]="item.isIntersection"
          ></app-type-action>
        </div>
        <div
          *ngSwitchCase="ItemType.button"
          class="container normal"
          [id]="item.id"
          (click)="changeTypeFooter(TypeFooter.Button, item, $event, i)"
        >
          <app-type-button
            class="item"
            [id]="item.id"
            [isIntersection]="item.isIntersection"
            [ctaElement]="ctaElement"
          ></app-type-button>
        </div>
        <!-- Nút thêm mới vào giữa các thành phần (phía dưới) -->
        <ng-container
          [ngTemplateOutlet]="buttonAddAbsolute"
          [ngTemplateOutletContext]="{
            index: i,
            isHide: item.isHideBtnAdd
          }"
        >
        </ng-container>
      </div>
    </div>
  </div>
  <!-- <button class="button-success" (click)="scrollToTop()">Scroll To Top</button> -->
</ng-template>
<!-- footer -->
<div>
  <app-edit-footer
    [typeFooter]="currentTypeFooter"
    [data]="data"
    (isSave$)="handleEmitIsSave($event)"
    (isDelete$)="deleteComponent($event)"
    (changePosition$)="handleChangePosition($event)"
    (saveChangePosition$)="callApiChangePosition($event)"
    (isSelectParallax$)="selectParallax($event)"
    [idSelected]="idSelected"
  ></app-edit-footer>
</div>

<ng-template #buttonAddAbsolute let-i="index" let-isHide="isHide">
  <div
    [ngClass]="{ hidden: isHide }"
    class="text-center absolute bottom-[-15px] left-1/2 translate-x-[-50%] z-10"
  >
    <button
      (click)="setPriority(i); openDrawerAdd()"
      class="px-3 py-1 text-sm bg-white text-black hover:bg-primary hover:text-white w-fit rounded-full shadow-md"
    >
      Thêm mới thành phần
    </button>
  </div>
</ng-template>

<ng-template #buttonAdd let-i="index">
  <div class="text-center mt-6 my-4">
    <button
      (click)="setPriority(i); openDrawerAdd()"
      style="padding: 4px"
      class="button-success hover:opacity-70 w-4/5"
    >
      Thêm mới thành phần
    </button>
  </div>
</ng-template>
<!-- back to top -->
<!-- <div
  class="fixed bottom-[100px] right-0 text-primary rounded-full text-lg w-12 h-12 border-[1px] border-primary border-solid flex items-center justify-center"
  (click)="scrollToTop()"
>
  <i class="fa-solid fa-arrow-up-from-bracket m-auto"></i>
</div> -->
<!-- <ng-template #iconScroll></ng-template> -->
