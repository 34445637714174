<nz-skeleton [nzActive]="true" [nzLoading]="isLoading"></nz-skeleton>
<div *ngIf="!isLoading" [formGroup]="formSelect" class="flex flex-col gap-y-5">
  <div>
    <div id="action-{{ id }}" class="h-fit py-2 bg-no-repeat bg-center bg-cover text-black"
      [ngClass]="{ 'text-white': item.bgImage }" [ngStyle]="{
        'background-image':
          item.bgImage && !item.backgroundColor
            ? 'url(' + item.bgImage + ')'
            : '',
        'background-color': item.backgroundColor ? item.backgroundColor : '',
        'padding-top': item.alignmentTop ? item.alignmentTop + 'px' : '0px',
        'padding-bottom': item.alignmentBottom
          ? item.alignmentBottom + 'px'
          : '0px',
        'padding-right': item.alignmentRight
          ? item.alignmentRight + '%'
          : '0px',
        'padding-left': item.alignmentLeft ? item.alignmentLeft + '%' : '0px'
      }">
      <div [ngClass]="{ 'text-black': !item.bgImage }" class="font-semibold text-base">
        {{ item.content }}
      </div>
      <form [formGroup]="form">
        <div *ngIf="item.name?.isShow" class="mt-2">
          <div class="mb-2" [ngClass]="{ 'text-black': !item.bgImage }">
            <span class="text-[red]">*</span> Họ tên
          </div>
          <input formControlName="fullName" type="text" nz-input />
        </div>
        <div *ngIf="item.email?.isShow" class="mt-2">
          <div class="mb-2" [ngClass]="{ 'text-black': !item.bgImage }">
            Email
          </div>
          <input formControlName="email" type="text" nz-input />
        </div>
        <div *ngIf="item.phone?.isShow" class="mt-2">
          <div class="mb-2" [ngClass]="{ 'text-black': !item.bgImage }">
            <span class="text-[red]">*</span> Số điện thoại
          </div>
          <input formControlName="phonenumber" type="text" nz-input />
        </div>
        <div *ngIf="item.address?.isShow" class="mt-2">
          <div class="mb-2" [ngClass]="{ 'text-black': !item.bgImage }">
            Địa chỉ
          </div>
          <input formControlName="address" type="text" nz-input />
        </div>
        <div *ngIf="item.birthday?.isShow" class="mt-2">
          <div class="mb-2" [ngClass]="{ 'text-black': !item.bgImage }">
            Sinh nhật
          </div>
          <nz-date-picker nzFormat="dd/MM/yyyy" class="w-full"></nz-date-picker>
        </div>
        <div *ngIf="item.note?.isShow" class="mt-2">
          <div class="mb-2" [ngClass]="{ 'text-black': !item.bgImage }">
            Ghi chú
          </div>
          <textarea formControlName="note" type="text" nz-input></textarea>
        </div>
        <div class="mt-7 w-full text-center">
          <button (click)="createConcern(form.value)" class="px-10 py-1 bg-[#EF4444] text-white">
            {{ item.btnActionText }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>