<div *ngIf="!isValidWebsite" class="relative min-h-screen w-full mx-auto">
  <img src="./../../../../../assets/images/not-found-page.svg" />
  <div class="text-center text-2xl font-semibold">Xin lỗi nhé!</div>
  <div class="font-medium text-center mt-2">
    Chúng tôi không thể tìm thấy trang đó, đừng lo lắng, mọi thứ vẫn tuyệt vời
    phải không?!
  </div>
</div>
<div *ngIf="isValidWebsite" class="relative min-h-screen w-full mx-auto">
  <!-- ----------- LOGO + Header ----------- -->
  <!--1. chế độ tự xem -->
  <div *ngIf="typePreview == TypePreview.Viewown" class="bg-white">
    <app-preview-heading
      class="w-full sm:w-responsivelarge z-10"
      title="Đang xem ở chế độ khách"
      routingBack="/pages/home"
    ></app-preview-heading>
    <div class="flex items-center mt-14 p-[0.75rem]">
      <div></div>
      <div
        *ngIf="previewContainer.logo"
        class="flex items-center h-[45px] w-[45px] mr-3"
      >
        <img [src]="previewContainer.logo" alt="" class="h-full" />
      </div>
      <div class="text-xl font-bold text-primary">
        {{ previewContainer.websiteName }}
      </div>
      <button
        nz-dropdown
        nzTrigger="click"
        [nzDropdownMenu]="share"
        class="p-0 h-8 w-8 flex-center bg-secondary-bg text-primary button ml-auto"
      >
        <i class="fa-sharp fa-solid fa-share-nodes"></i>
      </button>
      <nz-dropdown-menu #share="nzDropdownMenu">
        <ul nz-menu>
          <li
            nz-menu-item
            style="font-size: 16px; padding: 8px"
            (click)="shareLink()"
          >
            <i class="fa-solid fa-link mr-2"></i>
            <span>Chia sẻ liên kết</span>
          </li>
          <li
            nz-menu-item
            style="font-size: 16px; padding: 8px"
            (click)="shareQR()"
          >
            <i class="fa-solid fa-qrcode mr-2"></i>
            <span>Chia sẻ QR</span>
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>
  <!--2. chế độ người dùng khác xem khi chưa đăng nhập -->
  <div
    *ngIf="typePreview == TypePreview.Unlogin"
    class="flex items-center p-2 justify-between header"
  >
    <div class="flex items-center">
      <div
        *ngIf="previewContainer.logo"
        class="flex items-center h-[45px] w-[45px] mr-3"
      >
        <img
          [src]="previewContainer.logo"
          alt=""
          class="h-full aspect-square"
        />
      </div>
      <div class="text-xl font-bold text-primary">
        {{ previewContainer.websiteName }}
      </div>
    </div>
    <div>
      <button class="button-success px-2 py-1" (click)="login()">
        Đăng nhập
      </button>
    </div>
  </div>
  <!--3. chế độ người dùng khác xem khi đã đăng nhập -->
  <div
    *ngIf="typePreview == TypePreview.Login"
    class="flex items-center p-2 justify-between header"
  >
    <div class="flex items-center h-14">
      <button
        [routerLink]="['/pages/home']"
        class="p-0 h-8 w-8 flex-center bg-secondary-bg text-primary button mr-2"
      >
        <i class="fa-solid fa-arrow-left"></i>
      </button>
      <img
        *ngIf="previewContainer.logo"
        [src]="previewContainer.logo"
        alt=""
        class="h-[45px] w-[45px] mr-3"
      />
      <div class="text-xl font-bold text-primary">
        {{ previewContainer.websiteName }}
      </div>
    </div>
    <button
      nz-dropdown
      nzTrigger="click"
      [nzDropdownMenu]="share"
      class="p-0 h-8 w-8 flex-center bg-secondary-bg text-primary button ml-auto"
    >
      <i class="fa-sharp fa-solid fa-share-nodes"></i>
    </button>
    <nz-dropdown-menu #share="nzDropdownMenu">
      <ul nz-menu>
        <li
          nz-menu-item
          style="font-size: 16px; padding: 8px"
          (click)="shareLink()"
        >
          <i class="fa-solid fa-link mr-2"></i>
          <span>Chia sẻ liên kết</span>
        </li>
        <li
          nz-menu-item
          style="font-size: 16px; padding: 8px"
          (click)="shareQR()"
        >
          <i class="fa-solid fa-qrcode mr-2"></i>
          <span>Chia sẻ QR</span>
        </li>
      </ul>
    </nz-dropdown-menu>
  </div>
  <!-- ------- end LOGO + Header  ------ -->
  <div *ngIf="!!previewContainer.listBanner?.length">
    <app-type-image
      [item]="{ listImage: previewContainer.listBanner }"
      [isCallAPI]="false"
    ></app-type-image>
    <div class="" *ngIf="previewContainer.highLights?.text">
      <app-type-content
        [item]="previewContainer.highLights!"
        [isCallAPI]="false"
      ></app-type-content>
    </div>
  </div>

  <div class="card" *ngIf="typePreview === TypePreview.Home">
    <p class="font-semibold text-base">Phân loại thông tin</p>
    <!-- <pre>{{ selectedIndex }}</pre> -->
    <div>
      <p class="">Quản lí danh mục các bài đăng/sản phẩm và landing page</p>
      <ng-container *ngIf="isHasCategory; else dontHasCateogry">
        <div class="mt-1 grid grid-cols-2 gap-x-3">
          <button class="button button-success" (click)="createLandingPage()">
            Thêm mới landing page
          </button>
          <button class="button button-outline-success" (click)="category()">
            Quản lý danh mục
          </button>
        </div>
      </ng-container>
      <ng-template #dontHasCateogry>
        <img
          class="mt-5 mx-auto"
          src="./../../../../../assets/images/img-no-category.svg"
          alt=""
        />
        <div class="font-semibold mt-2">Chưa có danh mục</div>
        <button (click)="addCategory()" class="button-success mt-2 w-full py-2">
          Thêm mới danh mục ngay
        </button>
      </ng-template>
    </div>
  </div>

  <div *ngIf="isHasCategory" class="relative">
    <button
      class="flex-center text-primary w-6 h-6 duration-200 rounded-sm absolute left-0 top-[20px] -translate-y-1/2"
    >
      <i class="fa-sharp fa-solid fa-angle-left fa-beat-fade"></i>
    </button>
    <button
      class="flex-center text-primary w-6 h-6 duration-200 rounded-sm absolute right-0 top-[20px] -translate-y-1/2"
    >
      <i class="fa-sharp fa-solid fa-angle-right fa-beat-fade"></i>
    </button>
    <nz-tabset
      nzSize="small"
      (nzSelectChange)="changeTab($event)"
      [(nzSelectedIndex)]="selectedIndex"
      [nzAnimated]="true"
      [nzCentered]="true"
      [nzTabBarStyle]="{}"
    >
      <nz-tab
        *ngFor="let tabdetail of previewContainer.tabs; index as i"
        [nzTitle]="tabdetail.title!"
      >
        <!-- ========== LANDING PAGE ================ -->
        <div *ngIf="tabdetail.type === CategoryType.LandingPage">
          <ng-container *ngIf="!isDoneLoadingDetail; else doneLoadingLanding">
            <app-skeleton
              [isLoading]="!isDoneLoadingDetail"
              [skeletonCount]="2"
            ></app-skeleton>
          </ng-container>
          <ng-template #doneLoadingLanding>
            <ng-container *ngIf="isEmptyLanding; else detailLanding">
              <div *ngIf="typePreview === TypePreview.Home" class="p-2">
                <div>
                  <p class="font-semibold">Thêm mới thông tin</p>
                  <p class="mt-1">
                    Khi website của bạn có Landing page, đây là nơi bạn sẽ thu
                    hút khách hàng
                  </p>
                </div>
                <img
                  class="mt-3 mx-auto"
                  src="./../../../../../assets/images/img-category-no-content.svg"
                  alt=""
                />
                <div class="mt-2">
                  <p class="font-semibold">Chưa có thông tin</p>
                  <div
                    class="p-2 button-success w-full text-center mt-2"
                    (click)="editLandingPage(tabdetail)"
                  >
                    Tùy chỉnh landing page ngay
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-template>
          <ng-template #detailLanding>
            <div
              *ngIf="typePreview === TypePreview.Home"
              class="grid-cols-3 grid gap-x-5 p-2 mt-2 rounded-sm"
            >
              <div class="col-span-2">
                <p class="font-bold text-base">Quản lý landing page</p>
                <p>Cập nhật landing page để thu hút khách hàng của bạn</p>
              </div>
              <div class="flex items-center">
                <button
                  class="button button-success h-fit w-full"
                  (click)="editLandingPage(tabdetail)"
                >
                  Chỉnh sửa
                </button>
              </div>
            </div>
            <app-landing-page-view
              [id]="tabdetail.id"
              [isSelected]="tabdetail.isSelected!"
              [landingLink]="cta_url + tabdetail.code"
              [isShowCoppyBar]="isShowCoppyBar"
            >
            </app-landing-page-view>
          </ng-template>
        </div>
        <!-- =============== POST =================== -->
        <div *ngIf="tabdetail.type === CategoryType.Post">
          <ng-container *ngIf="!isDoneLoadingDetail; else doneLoadingPost">
            <app-skeleton
              [isLoading]="!isDoneLoadingDetail"
              [skeletonCount]="2"
            ></app-skeleton>
          </ng-container>
          <ng-template #doneLoadingPost>
            <ng-container *ngIf="isEmptyPost; else detailPost">
              <div *ngIf="typePreview === TypePreview.Home" class="p-2">
                <div>
                  <p class="font-semibold">Thêm mới thông tin</p>
                  <p class="mt-1">
                    Khi website của bạn có bài đăng, đây là nơi bạn sẽ thu hút
                    khách hàng
                  </p>
                </div>
                <img
                  class="mt-3 mx-auto"
                  src="./../../../../../assets/images/img-category-no-content.svg"
                  alt=""
                />
                <div class="mt-2">
                  <p class="font-semibold">Chưa có bài đăng</p>
                  <div
                    class="p-2 button-success w-full text-center mt-2"
                    (click)="createPost(tabdetail, i)"
                  >
                    Thêm mới bài đăng ngay
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-template>
          <ng-template #detailPost>
            <div
              *ngIf="typePreview === TypePreview.Home"
              class="p-2 mt-2 rounded-sm"
            >
              <div class="col-span-2">
                <p class="font-bold text-base">Quản lý bài đăng</p>
                <p>Quản lý, sắp xếp bài đăng dễ dàng</p>
              </div>
              <div class="mt-2 items-center grid grid-cols-2 gap-x-3">
                <button
                  class="button button-success"
                  (click)="createPost(tabdetail, i)"
                >
                  Thêm mới bài đăng
                </button>
                <button
                  class="button button-outline-success"
                  (click)="arrangePost(tabdetail.id, i)"
                >
                  Sắp xếp bài đăng
                </button>
              </div>
            </div>
            <app-post-view
              [id]="tabdetail.id"
              [isSelected]="tabdetail.isSelected!"
              [typePreview]="typePreview"
              [keyWebsite]="keyWebsite"
            >
            </app-post-view>
          </ng-template>
        </div>
      </nz-tab>
    </nz-tabset>
    <!-- <ng-template #extraTemplate>
      <i
        (click)="selectedIndex = selectedIndex + 1"
        class="fa-solid fa-circle-chevron-right text-primary text-lg"
      ></i>
    </ng-template> -->
    <ng-template #extraTemplate>
      <div class="custom-tab-buttons">
        <i
          *ngIf="selectedIndex >= 1"
          (click)="selectedIndex = selectedIndex - 1"
          class="fa-solid fa-circle-chevron-left text-primary text-lg"
        ></i>
        <div class="mx-1"></div>
        <i
          (click)="selectedIndex = selectedIndex + 1"
          class="fa-solid fa-circle-chevron-right text-primary text-lg"
        ></i>
      </div>
    </ng-template>
  </div>

  <!-- social media -->
  <div
    *ngIf="typePreview != TypePreview.Home"
    class="fixed right-3 bottom-[100px] text-right flex flex-col gap-y-3 z-50"
  >
    <div
      class="flex justify-end blink"
      *ngIf="previewContainer.contactArray?.linkProfile?.isShow"
    >
      <a
        href="{{ previewContainer.contactArray?.linkProfile?.href }}"
        target="_blank"
      >
        <img
          class="rounded-full border-primary border-solid border"
          width="45px"
          [src]="previewContainer.contactArray?.linkProfile?.icon"
          alt=""
        />
      </a>
    </div>
    <div
      class="flex justify-end blink"
      *ngIf="previewContainer.contactArray?.email?.isShow"
    >
      <a href="mailto:{{ previewContainer.contactArray?.email?.href }}">
        <img
          width="45px"
          [src]="previewContainer.contactArray?.email?.icon"
          alt=""
        />
      </a>
    </div>
    <div
      class="flex justify-end blink"
      *ngIf="previewContainer.contactArray?.phone?.isShow"
    >
      <a href="tel:{{ previewContainer.contactArray?.phone?.href }}">
        <img
          width="45px"
          [src]="previewContainer.contactArray?.phone?.icon"
          alt=""
        />
      </a>
    </div>
    <div
      class="flex justify-end blink"
      *ngIf="previewContainer.contactArray?.zalo?.isShow"
    >
      <a (click)="navigateToZalo()">
        <img
          width="45px"
          [src]="previewContainer.contactArray?.zalo?.icon"
          alt=""
        />
      </a>
    </div>
    <div
      class="flex justify-end blink"
      *ngIf="previewContainer.contactArray?.messenger?.isShow"
    >
      <a (click)="navigateToFacebook()"
        ><img
          width="45px"
          [src]="previewContainer.contactArray?.messenger?.icon"
          alt=""
        />
      </a>
    </div>
  </div>
</div>
