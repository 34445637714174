// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  ENV: 'development',
  production: false,
  TRUE_URL: 'https://me-dev.eztek.net',
  TRUE_WEBSITE: 'http://me-dev.trueconnect.vn',
  URL_CDN_UPLOAD: 'https://cdn-test.eztek.net/gateway/Media/Upload',
  API_URL: 'https://api-dev.trueconnect.vn',
  WEB_REDIRECT_URL: 'https://web-dev.trueconnect.vn/pages',
  SSO_URL: 'https://id-test.trueconnect.vn',
  URL_ENV: 'https://web-dev.trueconnect.vn',
  CLIENT_ID: 'localhost_identity_short',
  CLIENT_SECRET: 'no_important',
  WEBMINI_URL: 'https://web-dev.trueconnect.vn',
  CTA_URL: 'https://cta-dev.trueconnect.vn/',
  CONNECT_URL: 'https://hub-server-test.trueconnect.vn',
  ID_API: 'https://id-api-test.trueconnect.vn',
  CHANGE_PWD_URL: "https://id-test.trueconnect.vn/Manage/ChangePassword",
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
