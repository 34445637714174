<app-page-header [title]="'Tạo mới trang landingpage'"></app-page-header>
<!-- <div (click)="logout()">Logout</div> -->
<div class="flex flex-col gap-y-7 p-3">
  <form [formGroup]="form">
    <div class="flex flex-col">
      <span class="font-semibold mb-2">1. Tạo mới hoàn toàn</span>
      <label for="categoryId" class="font-semibold mb-2"><span class="text-red-500">*</span> Chọn danh mục</label>

      <div class="flex justify-between">
        <nz-select class="!w-[73%]" formControlName="categoryId" nzShowSearch nzAllowClear
          nzPlaceHolder="Tìm kiếm danh mục" [nzDropdownRender]="renderTemplate">
          <nz-option *ngFor="let cate of categoryList" [nzLabel]="cate.categoryName!" [nzValue]="cate.id"></nz-option>
        </nz-select>
        <button (click)="createNewComplete()" class="button-success px-2 w-1/4">
          Tạo mới
        </button>
      </div>
      <ng-template #renderTemplate>
        <div (click)="createCategory()"
          class="flex items-center px-3 py-1 text-blue-text cursor-pointer hover:bg-[#0eaf8f] hover:text-white">
          <i class="fa-solid fa-plus mr-2"></i>
          <span>Thêm danh mục</span>
        </div>
      </ng-template>
    </div>
    <!-- <div class="flex justify-between mt-4">
      <div></div>
      <button (click)="createNewComplete()" class="button-success px-2">
        Tạo mới
      </button>
    </div> -->
  </form>
  <form [formGroup]="form1">
    <div>
      <div class="font-semibold mb-2">2. Tạo mới bằng link giao diện</div>
      <div class="flex justify-between items-center">
        <input type="text" nz-input placeholder="Nhập link giao diện" formControlName="ctaLink" class="!w-[65%]" />
        <button (click)="pasteLandingPage()" class="bg-blue-button !w-[33%] px-3 py-[6px] text-white">Dán liên
          kết</button>
      </div>
    </div>
  </form>
  <div>
    <div class="font-semibold">3. Tạo theo mẫu sẵn có</div>
    <div *ngIf="listTemplate" class="grid grid-cols-2 gap-5">
      <div class="mt-3" *ngFor="let item of listTemplate">
        <div class="image image-container">
          <img class="h-[250px] image object-cover w-full" nz-image nzDisablePreview="true" [nzSrc]="item.image!" />
          <div class="image-detail flex items-center flex-col">
            <a target="_blank" [href]="item.ctaLink">
              <button class="w-[120px] bg-white text-primary" nz-button>
                Xem chi tiết
              </button>
            </a>
            <button (click)="applyTemplate(item.name!, item.ctaLink!)"
              class="mt-2 w-[120px] bg-blue-button text-white" nzType="default">
              Áp dụng
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>