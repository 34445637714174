<form [formGroup]="actionForm" class="mb-12 flex flex-col gap-y-5">
  <div>
    <div class="mb-1">
      <span>Tên lời kêu gọi</span>
    </div>
    <input formControlName="content" nz-input placeholder="Basic usage" />
  </div>
  <div class="font-medium text-base">Thông tin</div>
  <div>
    <div class="flex justify-between items-center mb-2">
      <span>Họ tên</span>
      <nz-switch formControlName="name"></nz-switch>
    </div>
    <!-- <input nz-input placeholder="Basic usage" /> -->
  </div>
  <div>
    <div class="flex justify-between items-center mb-2">
      <span>Email</span>
      <nz-switch formControlName="email"></nz-switch>
    </div>
    <!-- <input nz-input placeholder="Basic usage" /> -->
  </div>
  <div>
    <div class="flex justify-between items-center mb-2">
      <span>Số điện thoại</span>
      <nz-switch formControlName="phone"></nz-switch>
    </div>
    <!-- <input nz-input placeholder="Basic usage" /> -->
  </div>
  <div>
    <div class="flex justify-between items-center mb-2">
      <span>Địa chỉ</span>
      <nz-switch formControlName="address"></nz-switch>
    </div>
    <!-- <input nz-input placeholder="Basic usage" /> -->
  </div>
  <div>
    <div class="flex justify-between items-center mb-2">
      <span>Sinh nhật</span>
      <nz-switch formControlName="birthday"></nz-switch>
    </div>
    <!-- <nz-date-picker nzFormat="dd/MM/yyyy" class="w-full"></nz-date-picker> -->
  </div>
  <div>
    <div class="flex justify-between items-center mb-2">
      <span>Lời nhắn</span>
      <nz-switch formControlName="note"></nz-switch>
    </div>
    <!-- <textarea rows="2" nz-input></textarea> -->
  </div>
  <div>
    <div class="mb-1">
      <span class="text-red-500 mr-2">*</span>
      <span>Nội dung nút hành động</span>
    </div>
    <input formControlName="buttonText" nz-input placeholder="Basic usage" />
  </div>
</form>
<!-- footer -->
<div class="absolute bottom-0 left-0 right-0 bg-white">
  <div class="flex items-center justify-between px-5 py-2">
    <button
      class="button-outline-default grow hover:opacity-70 mr-4"
      (click)="cancel()"
    >
      Hủy
    </button>

    <button
      *ngIf="type === TypeComponent.Edit"
      [disabled]="actionForm.invalid"
      class="button-success grow hover:opacity-70"
      (click)="update()"
    >
      Cập nhật
    </button>
    <button
      *ngIf="type === TypeComponent.Create"
      [disabled]="actionForm.invalid"
      class="button-success grow hover:opacity-70"
      (click)="create()"
    >
      Thêm mới
    </button>
  </div>
</div>
