import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { KeyStorage } from 'src/app/core/enums';
import { LandingPageService } from 'src/app/core/services/landingPage.service';
import { NotiService } from 'src/app/core/services/noti.service';
import { TypeComponent } from '../../../enums/typeComponents.enum';
import {
  ItemType,
  listActionsOptionsEnum,
  ResponseAction,
  TypeAction,
} from '../../../models/screenEdit';
import { ActionService } from '../../../services/action.service';

@Component({
  selector: 'app-edit-type-action',
  templateUrl: './edit-type-action.component.html',
  styleUrls: ['./edit-type-action.component.scss'],
})
export class EditTypeActionComponent implements OnInit {
  @Input() type: TypeComponent = TypeComponent.Create;
  @Input() idItemSelected: string = ''; // landingID
  @Input() isFromButtonDialog: boolean = false; // xét xem có phải mở từ button dialog không
  @Input() dataFromButtonDialog: any;

  item: TypeAction = new TypeAction();

  TypeComponent = TypeComponent;
  actionForm: FormGroup = this.fb.group({
    content: [''],
    name: [true],
    phone: [true],
    email: [true],
    address: [true],
    birthday: [true],
    note: [true],
    buttonText: ['', [Validators.required]],
  });
  constructor(
    private ref: NzDrawerRef,
    private fb: FormBuilder,
    private landingService: LandingPageService,
    private noti: NotiService,
    private actionService: ActionService
  ) {}

  ngOnInit(): void {
    if (this.type === TypeComponent.Edit && this.idItemSelected) {
      // console.log(this.idItemSelected);
      this.getDetailAndPatchValue();
    }
  }

  getDetailAndPatchValue() {
    // nếu từ action bình thường -> call api -> patch value
    if (!this.isFromButtonDialog) {
      this.landingService.getDetailAction(this.idItemSelected).subscribe(
        (next: ResponseAction) => {
          this.item = this.actionService.mapResponseToView(next);
          // patch to form
          this.actionForm.patchValue({
            content: this.item.content,
            name: this.item.name?.isShow,
            phone: this.item.phone?.isShow,
            email: this.item.email?.isShow,
            address: this.item.address?.isShow,
            birthday: this.item.birthday?.isShow,
            note: this.item.note?.isShow,
            buttonText: this.item.btnActionText,
          });
        },
        (err) => {
          this.noti.warning();
        }
      );
    }
    // nếu từ type button
    else {
      this.item = this.actionService.mapResponseToView(
        this.dataFromButtonDialog
      );
      console.log(this.item);
      this.actionForm.patchValue({
        content: this.item.content,
        name: this.item.name?.isShow,
        phone: this.item.phone?.isShow,
        email: this.item.email?.isShow,
        address: this.item.address?.isShow,
        birthday: this.item.birthday?.isShow,
        note: this.item.note?.isShow,
        buttonText: this.item.btnActionText,
      });
    }
  }
  cancel() {
    this.ref.close();
  }
  update() {
    let body: any = this.actionService.mapViewToResponse(
      this.actionForm.value,
      this.idItemSelected
    );
    body.id = this.item?.id;
    // nếu không phải từ màn button -> update action bình thường -> call api
    if (!this.isFromButtonDialog) {
      this.landingService.updateAction(body).subscribe(
        (next) => {
          this.noti.success();
          this.emitSuccess();
        },
        (error) => {
          this.noti.warning();
        }
      );
    } else {
      this.ref.close(body);
    }
  }

  create() {
    let formValue = this.actionForm.value;
    this.landingService
      .addItem({
        categoryID: localStorage.getItem(KeyStorage.cetagory_id),
        type: ItemType.action,
        priority: localStorage.getItem(KeyStorage.priority),
      })
      .subscribe(
        (next) => {
          if (!next) return;

          this.landingService
            .addAction({
              ...this.actionService.mapViewToResponse(formValue, next.id),
              alignmentRight: 5,
              alignmentLeft: 5,
            })
            .subscribe(
              (next) => {
                if (!next) return;
                this.noti.success('Thêm mới hành động thành công');
                this.emitSuccess();
              },
              (error) => {
                this.noti.warning();
              }
            );
        },
        (err) => {
          this.noti.warning();
        }
      );
    let isSaveAction: IsSaveAction = {
      isSave: true,
      data: this.actionForm.value,
    };
    this.ref.close(isSaveAction);
  }

  emitSuccess() {
    this.ref.close(true);
  }
}

export class Keys {
  key!: string;
  value!: listActionsOptionsEnum;
}

export class IsSaveAction {
  isSave?: boolean;
  data?: {
    content: string;
    name?: boolean;
    phone?: boolean;
    email?: boolean;
    address?: boolean;
    birthday?: boolean;
    note?: boolean;
    buttonText: string;
  };
}
